import axios from "axios";
import TEST_OR_PROD from "./TEXT_OR_PROD";

const API_BASE_URL =
  TEST_OR_PROD == 0
    ? "http://localhost:8080"
    : "https://server.gurkanucar.com/kangaroo_001";

//: "http://139.162.164.68:8080/kangaroo";

const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    console.error("Axios error:", error);
    return Promise.reject(error);
  }
);

//CATEGORY actions
export const getCategoryList = async () => {
  const response = await instance.get("/api/product/category");
  return response.data;
};

export const deleteCategory = async (item) => {
  const response = await instance.delete(`/api/product/category/${item.id}`);
  return response.data;
};

export const saveCategory = async (item) => {
  const response = await instance.post("/api/product/category", item);
  return response.data;
};

//CATEGORY actions

//OPTIONSET actions

export const saveOptionSet = async (item) => {
  const response = await instance.post("/api/product/optionSet", item);
  return response.data;
};

export const deleteOptionSet = async (item) => {
  const response = await instance.delete(`/api/product/optionSet/${item.id}`);
  return response.data;
};

export const getOptionSetList = async () => {
  const response = await instance.get("/api/product/optionSet");
  return response.data;
};

//OPTIONSET actions

//OPTIONSETITEMS actions

export const saveOptionItemSet = async (item) => {
  const response = await instance.post("/api/product/optionSetItem", item);
  return response.data;
};

export const deleteOptionItemSet = async (item) => {
  console.log(item);
  const response = await instance.delete(
    `/api/product/optionSetItem/${item.id}`
  );
  console.log(response);
  return response.data;
};

export const getOptionSetItemList = async (optionSet) => {
  const response = await instance.get(
    `/api/product/optionSetItem/optionSetRef/${optionSet.id}`
  );

  return response.data;
};

//OPTIONSETITEMS actions

//PHOTO actions

export const getPhotoByProductGroupRef = async (item) => {
  const response = await instance.get(
    `/api/common/photo/productGroupRef/${item.id}`
  );

  return response.data;
};

export const getPhotoByOptionSetItemRef = async (item) => {
  const response = await instance.get(
    `/api/common/photo/optionSetItem/${item.id}`
  );

  return response.data;
};

export const getPhotoByProductRef = async (item) => {
  const response = await instance.get(
    `/api/common/photo/productRef/${item.id}`
  );

  return response.data;
};

export const savePhoto = async (item) => {
  console.log(item);
  const response = await instance.post("/api/common/photo", item);
  return response.data;
};

//PHOTO ACTIONS

//PRODUCT actions

export const saveProduct = async (item) => {
  const response = await instance.post("/api/product", item);
  return response.data;
};

export const deleteProduct = async (item) => {
  const response = await instance.delete(
    `/api/product/optionSetItem/${item.id}`
  );
  return response.data;
};

export const getProductList = async (optionSet) => {
  const response = await instance.get("/api/product/");

  return response.data;
};

//PRODUCT actions

//InvoiceLinesDTO
//
export const getLines = async (id) => {
  const response = await instance.get(`/api/invoice/lines/${id}`);
  return response.data;
};
//InvoiceLinesDTO
//GLOBAL FUNTIONS

export const getList = async (modelName) => {
  const response = await instance.get(`/api/${modelName}`);

  return response.data;
};

export const save = async (modelName, model) => {
  const response = await instance.post(`/api/${modelName}`, model);

  return response.data;
};

export const del = async (modelName, model) => {
  const response = await instance.delete(`/api/${modelName}/${model.id}`);

  return response.data;
};
//GLOBAL FUNTIONS
