import React, { useState } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  Form,
  Input,
  Layout,
  theme,
  Typography,
  Button,
  Divider,
  message,
} from "antd";
import KangarooMenu from "./components/menu/kangaroo_menu";
import CategoryList from "./components/product/category/category_list";
import OptionSetList from "./components/product/option_set/option_set_list";
import ProductList from "./components/product/product/product_list";
import LoginForm from "./components/login/login_form";
import "./App.css";
import ExtraList from "./components/product/extra/extra_list";
import InvoiceList from "./components/report/sale/invoice_list";
import SaleTotalReport from "./components/report/sale/sale_total_report";
import ProductPriceList from "./components/productPrice/product_price_list";

const { Header, Content, Footer, Sider } = Layout;
const loginError = () => {
  message.info("Kullanıcı adı veya şifre hatalıdır!");
};

const App = () => {
  const [menu, setMenu] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [form] = Form.useForm();

  const [fields, setFields] = useState([
    {
      name: ["email"],
      value: "",
    },
    {
      name: ["password"],
      value: "",
    },
  ]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onClick = (e) => {
    console.log(e.key);
    setMenu(e.key);
  };

  return (
    <div>
      {!loggedIn && (
        <div className="appBg">
          <Form
            name="login_form"
            className="loginForm"
            style={{ maxWidth: 800 }}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 20,
            }}
            onFieldsChange={(changedFields, allFields) => {
              setFields(allFields);
            }}
            fields={fields}
            onFinish={(values) => {
              if (values.email === undefined) {
                return;
              }

              if (values.password === undefined) {
                return;
              }

              if (values.email == "admin" && values.password == "198123") {
                setLoggedIn(true);
              } else {
                loginError();
              }
            }}
          >
            <Typography.Title style={{ textAlign: "center" }}>
              Kangaroo Login
            </Typography.Title>
            <Form.Item
              label="E-Posta"
              name="email"
              rules={[
                {
                  required: true,
                  message: "E-Posta Adresi zorunludur!",
                },
              ]}
            >
              <Input placeholder="E-Posta adresinizi giriniz" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Şifre zorunludur!",
                },
              ]}
            >
              <Input.Password placeholder="Şifre giriniz" />
            </Form.Item>
            <Button type="primary" htmlType="submit" block>
              Login
            </Button>
            <Divider style={{ borderColor: "black" }}>
              mail@kangaroo.com
            </Divider>
            <div></div>
          </Form>
        </div>
      )}

      {loggedIn && (
        <Layout>
          <Header></Header>
          <Content>
            <Layout>
              <Sider width={250}>
                <KangarooMenu onClick={onClick} />
              </Sider>
              <Content style={{ margin: 20 }}>
                {menu == 1 && <h1>Ana Sayfa</h1>}
                {menu == 2 && <CategoryList />}
                {menu == 3 && <OptionSetList />}
                {menu == 4 && <ProductList />}
                {menu == 5 && <ExtraList />}
                {menu == 6 && <InvoiceList />}
                {menu == 7 && <SaleTotalReport />}
                {menu == 8 && <ProductPriceList />}
              </Content>
            </Layout>
          </Content>

          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Kangaroo App v.1.0.1
          </Footer>
        </Layout>
      )}
    </div>
  );
};
export default App;
