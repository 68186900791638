import { useState } from "react";
import {
  TagOutlined,
  CalendarOutlined,
  LinkOutlined,
  MailOutlined,
  SettingOutlined,
  PlusSquareOutlined,
  BarsOutlined,
  NumberOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";

import { Menu } from "antd";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Ana Sayfa", "1", <MailOutlined />),
  getItem("Stok Grup Listesi", "2", <CalendarOutlined />),
  getItem("Opsiyon Listesi", "3", <CalendarOutlined />),
  getItem("Stok Kartları", "4", <TagOutlined />),
  getItem("Extra Listesi", "5", <PlusSquareOutlined />),
  getItem("Satış Hareketleri Listesi", "6", <BarsOutlined />),
  getItem("Satış Toplam Raporu", "7", <NumberOutlined />),
  getItem("Fiyat Panosu", "8", <BarcodeOutlined />),
];

const KangarooMenu = ({ onClick }) => {
  return (
    <Menu
      onClick={onClick}
      style={{
        width: 250,
      }}
      defaultSelectedKeys={["1"]}
      items={items}
    />
  );
};

export default KangarooMenu;
